
/* 
  @font-face {
    font-family: "Helvetica Now Text";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Helvetica Now Text Regular"), local("Helvetica Now Text-Regular"),
      url("./assets/fonts/HelveticaNowText-Regular.ttf") format("truetype");
  }
 
  @font-face {
    font-family: "Helvetica Now Text";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Helvetica Now Text Bold"), local("Helvetica Now Text-Bold"),
      url("./assets/fonts/HelveticaNowText-Bold.ttf") format("truetype"); 
  }
 
  @font-face {
    font-family: "Helvetica Now Text";
    font-style: normal;
    font-weight: 900;
    src: local("Helvetica Now Text Black"), local("Helvetica Now Text-Black"),
      url("./assets/fonts/HelveticaNowText-Black.ttf") format("truetype"); 
  } */
  /* body{
    font-family: "Helvetica Now Text", sans-serif;
  } */
  /* noto-sans-regular - latin_cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/noto-sans-v11-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin_cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/noto-sans-v11-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/noto-sans-v11-latin_cyrillic-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}